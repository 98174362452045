<template>
  <div class="">
       <div class="flex items-center h-full fixed right-0 z-10">
          <button @click="toggle" class="bg-AnharAlkhair w-6 md:w-10 py-1 text-white font-extrabold text-lg md:text-3xl rounded-l-xl flex justify-center items-center focus:outline-none">
              +
          </button>
      </div>
    <section v-if="info" id="info" class="bg-AnharAlkhair inset-0 absolute z-50 overflow-y-scroll">
      <div class="max-w-8xl mx-auto py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0">
        <button @click="toggle" class="w-6 h-6 rounded-full left-0 focus:outline-none">
          <svg :class="`${$i18n.locale}` == 'ar' ? '' : 'transform rotate-180'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 490.667 490.667" style="enable-background:new 0 0 490.667 490.667;" xml:space="preserve">
          <path style="fill:white;" d="M245.333,0C109.839,0,0,109.839,0,245.333s109.839,245.333,245.333,245.333
            s245.333-109.839,245.333-245.333C490.514,109.903,380.764,0.153,245.333,0z"/>
          <path style="fill:#921a35;" d="M290.133,104.533c-19.139-18.289-49.277-18.289-68.416,0c-18.893,18.881-18.902,49.503-0.021,68.395
            c0.007,0.007,0.014,0.014,0.021,0.021l19.2,19.2H117.333C87.878,192.149,64,216.027,64,245.483
            c0,29.455,23.878,53.333,53.333,53.333h123.584l-19.2,19.2c-17.522,20.175-15.371,50.734,4.804,68.257
            c18.259,15.858,45.423,15.799,63.612-0.139l118.251-118.251c12.492-12.496,12.492-32.752,0-45.248L290.133,104.533z"/>
          </svg>
        </button>
        <div class="mt-6 md:mt-10 lg:mt-16 lg:flex">
          <div class="lg:w-3/12 text-white text-lg">
            <div class="fontBold text-2xl md:mb-2">+ {{ $t("AnharAlkhair.project_info.project_info") }}</div>
            <br>
            {{ $t("AnharAlkhair.project_info.work.1") }} /
            <br class="hidden lg:block">
            {{ $t("AnharAlkhair.project_info.work.2") }} /
            <br class="hidden lg:block">
            {{ $t("AnharAlkhair.project_info.work.3") }} /
            <br class="hidden lg:block">
            {{ $t("AnharAlkhair.project_info.work.4") }} /
          </div>
          <div class="lg:w-9/12 text-white mt-6">
            <p class="text-4xl fontBold">{{ $t("AnharAlkhair.project_info.description.title") }}</p>

            <div class="">
              <p class="mt-6  leading-7 text-lg">
                {{ $t("AnharAlkhair.project_info.description.content_1") }}
              </p>

              <p class="mt-6  leading-7 text-lg">
                 {{ $t("AnharAlkhair.project_info.description.content_2") }}
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="hero" class="w-full h-64 md:h-auto lg:h-screen relative bg-center">
   
      <div class="flex justify-center items-center h-full relative">
        <img src="../assets/images/AnharAlkhair/firstimage.jpg" alt="AnharAlkhair" class="w-full h-full">
        
      </div>
    </section>

    <section class="background_AnharAlkhair bg-right bg-no-repeat bg-clip-text lg:bg-clip-content ">
      <div class="max-w-6xl mx-auto px-4 lg:px-0" :class="`${$i18n.locale}` == 'ar' ? 'flex justify-end' : ''">
        <div class="lg:w-9/12 py-8 md:py-20 max-w-xl">
          <p class="text-lg md:text-5xl lg:text-6xl fontBold">{{ $t("AnharAlkhair.1.title") }}</p>
          <p class="text-sm md:text-base mt-4 ">{{ $t("AnharAlkhair.1.description") }}</p>
        </div>
      </div>
    </section>
      
    <section id="The_present_past_1" class="flex max-w-8xl mx-auto py-4 md:py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0">
      <div class="">
        <img src="../assets/images/AnharAlkhair/The_present_past_2.jpg" alt="">
      </div>

      <div class="">
        <img src="../assets/images/AnharAlkhair/The_present_past_1.jpg" alt="">
      </div>
    </section>

    <section id="basic_needs" class="flex max-w-8xl mx-auto py-4 md:py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0">
      <div class="md:w-3/5 ">
          <p class="text-lg md:text-5xl lg:text-6xl fontBold">{{ $t("AnharAlkhair.2.title") }}</p>
        <p class="text-sm md:text-base  mt-4 ">{{ $t("AnharAlkhair.2.description") }}</p>
      </div>
      <div class="md:w-2/5 hidden md:flex justify-center">
        <img src="../assets/images/AnharAlkhair/02-LOGO.svg" alt="" class="w-64 h-64">
      </div>
    </section>

    <section class="max-w-8xl mx-auto py-4 md:py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0">
      <div class="w-full flex items-stretch space-x-2 md:space-x-8 ">
        <div class="w-3/5">
          <img src="../assets/images/AnharAlkhair/02-STATIONARY.jpg" alt="" class="h-70 md:h-80 object-cover w-full">
        </div>
        <div class="w-2/5">
          <img src="../assets/images/AnharAlkhair/03-A4.jpg" alt="" class="h-70 md:h-80 object-cover w-full">
        </div>
      </div>

      <div class="max-w-2xl mx-auto mt-8 md:mt-24">
        <img src="../assets/images/AnharAlkhair/04-SUB LOGOS.svg" alt="">
      </div>
    </section>

    <section id="Vibrant" class="COLORS_AnharAlkhair bg-right bg-no-repeat bg-clip-text lg:bg-clip-content">
      <div class="max-w-6xl mx-auto px-4 lg:px-0" :class="`${$i18n.locale}` == 'ar' ? 'flex justify-end' : ''">
        <div class="lg:w-9/12 py-8 md:py-20 max-w-xl">
          <p class="text-lg md:text-5xl lg:text-6xl fontBold">{{ $t("AnharAlkhair.3.title") }}</p>
          <p class="text-sm md:text-base  mt-4 ">{{ $t("AnharAlkhair.3.description") }}</p>
        </div>
      </div>
    </section>

    <section class="max-w-6xl mx-auto px-4 lg:px-0 pt-8">
      <div class="">
        <img src="../assets/images/AnharAlkhair/03-THREE PATTERNS.svg" alt="">
      </div>

      <div class="mt-8 md:mt-24 mb-20 md:mb-40">
        <img src="../assets/images/AnharAlkhair/04-LOGO MULTICOLOR.jpg" alt="">
      </div>
    </section>

    <div class="text-xl md:text-3xl flex justify-center text-white bg-footer pt-4 linkwork fontRg -mt-1 -mb-1 z-10">
      <router-link :to="`/${$i18n.locale}/Dima`">
        {{ $t("Next-Project") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
      return {
        info : false
      }
  },
  mounted() {
    scrollTo(0, 0)
  },
  methods:{
    toggle(){
      if(this.info == true){
        this.info = false
        document.body.style.overflow = ""
      }else{
        this.info = true
        scrollTo(0, 0)
        document.body.style.overflow = "hidden"
      }
      
    }
  }
}
</script>

<style>
  .background_AnharAlkhair{
    background-image: url('../assets/images/AnharAlkhair/PATTERN_en.svg')
  }

  .COLORS_AnharAlkhair{
    background-image: url('../assets/images/AnharAlkhair/05-COLORS.svg');
    
  }
</style>